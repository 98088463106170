export default function menu() {

  // スクロールイベント
  window.addEventListener("scroll", function () {

    var headerElement = document.getElementById("headerFixed"); // `#header`セレクタを取得
    var rect = headerElement.getBoundingClientRect(); //
    var y = rect.top + window.pageYOffset; // Y方向 (縦)にスクロール量を取得（pageYOffset：windowオブジェクト。現在表示位置のY座標を取得）
    if (y > 150) { // 変数yの値が0よりも
      // headerElement.classList.remove('hidden');
      headerElement.classList.add('active'); // そうでなければhiddensセレクタを追加する
    } else {
      // headerElement.classList.add('hidden');
      headerElement.classList.remove('active'); // 大きければhiddensセレクタを削除する
    }
  });

}
