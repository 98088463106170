export default function menu() {

  if (window.matchMedia('(max-width: 1000px)').matches) {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('#cvBtn').fadeIn();
      } else {
        $('#cvBtn').fadeOut();
      }
    });

  }
}
