import "picturefill";
import "intersection-observer";

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// アコーディオン
import accordion from "./modules/accordion";
accordion();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();

// ヘッダー
import header from "./modules/header";
header();

// CVボタン
import cvBtn from "./modules/cvBtn";
cvBtn();
