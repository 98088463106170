export default function menu() {

  document.addEventListener("DOMContentLoaded", () => {

    if (window.matchMedia('(min-width: 1081px)').matches) {

      $(".--hvBtn").hover(function () {
        $(this).children('.gnav .sublist').fadeIn(30);
        $(this).addClass('is-active');
      }, function () {
        $(this).children('.gnav .sublist').fadeOut(30);
        $(this).removeClass('is-active');
      });

    } else {
      const acdBtns = [...document.querySelectorAll('.acdBtn')];

      function test(event) {
        const sublist = event.currentTarget.nextElementSibling;
        event.currentTarget.classList.toggle('is-active');
        sublist.classList.toggle('is-open');
      }

      acdBtns.forEach((acdBtn) => {
        acdBtn.addEventListener('click', test, false);
      });
    }
  });
}
