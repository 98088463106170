import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
export default function menu(drawer) {

  //  ドロワー
  var state = false;
  $(".gnav-sp").on("click", function () {
    if (state == false) {
      document.documentElement.style.overflow = 'hidden';
      disableBodyScroll(document.querySelector('.drawerModal'));
      $('.drawerBtn').addClass('active');
      $('.drawerModal').addClass('open');
      $('.headerLogo').fadeOut(40);
      $('#headerFixed').addClass('open');
      state = true;
    } else {
      document.documentElement.style.overflow = '';
      enableBodyScroll(document.querySelector('.drawerModal'));
      $('.drawerBtn').removeClass('active');
      $('.drawerModal').removeClass('open');
      $('.headerLogo').fadeIn(40);
      $('#headerFixed').removeClass('open');
      state = false;
    }
  });

  $('.jsClose').on('click', function () {
    $('.gnav-sp').click();
    document.documentElement.style.overflow = '';
    enableBodyScroll(document.querySelector('.drawerModal'));
    $('.drawerBtn').removeClass('active');
    $('.drawerModal').removeClass('open');
    $('.headerLogo').fadeIn(40);
    $('#headerFixed').removeClass('open');
    state = false;
  });
}
